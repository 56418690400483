// TODO: move file back to ./viewer/Menu.tsx
import React, { useMemo } from 'react';
import type { IMenuProps } from '../Menu.types';
import { MenuContextProvider } from './MenuContext';
import MenuContent from './components/MenuContent/MenuContent';

const Menu: React.FC<IMenuProps> = props => {
  const {
    id,
    containerRootClassName,
    // children,
    slots,
    items,
    partToPreviewStateMap,
    currentUrl,
    activeAnchor,
    onItemMouseIn,
    onItemMouseOut,
    onItemClick,
    onItemDblClick,
    translations,
  } = props;

  const itemsWithChildren = useMemo(
    () =>
      slots
        ? items.map(item => ({
            ...item,
            children: item.slot && slots[item.slot],
          }))
        : items,
    [items, slots],
  );

  return (
    <div id={id} className={containerRootClassName}>
      <MenuContextProvider
        items={itemsWithChildren}
        partToPreviewStateMap={partToPreviewStateMap}
        currentUrl={currentUrl}
        activeAnchor={activeAnchor}
      >
        <MenuContent
          id={`${id}-menu-content`}
          translations={translations}
          onItemMouseIn={onItemMouseIn}
          onItemMouseOut={onItemMouseOut}
          onItemClick={onItemClick}
          onItemDblClick={onItemDblClick}
        />
        {/* TODO: render children for the HamburgerMenu integration */}
      </MenuContextProvider>
    </div>
  );
};

export default Menu;
